import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PeopleIcon from "@material-ui/icons/People";

import { useProjectAllUsers } from "services/ProjectService";
import { useMobileLayout } from "../hooks/uiHooks";
import { TEAM_SORT_OPTION_FIELD, TEAM_SORT_OPTION } from "../utils/teamUtils";

import Spinner from "./Spinner";
import ProjectTeamMemberCard from "./cards/teamMemberCard/ProjectTeamMemberCard";
import { EmptyData } from "./emptyData";
import { ProjectStrings } from "strings";
import MainContext from "context/MainContext";

const DISPLAY_ITEMS = {
  DEFAULT: 24,
  // MAX: 120,
  LOAD_MORE: 12,
  MOBILE_DIVIDER: 6,
};

const useStyles = makeStyles((theme) => ({
  root: (mobile) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  }),
  cardContainer: (mobile) => ({
    padding: theme.spacing(1),
    width: mobile ? (window.innerWidth / 2) * 0.9 : 190,
    height: 300,
  }),
}));

const ProjectTeamList = ({
  config: { projectId, onClick, onOpenCode, progressId },
  canReadPermissions,
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  const { sortDescValue, sortFieldValue, searchValue } =
    useContext(MainContext);

  const sort =
    sortFieldValue && sortFieldValue in TEAM_SORT_OPTION_FIELD
      ? TEAM_SORT_OPTION_FIELD[sortFieldValue]
      : TEAM_SORT_OPTION_FIELD[TEAM_SORT_OPTION[0]];

  const params = {
    limit: DISPLAY_ITEMS.MAX,
    startAt: 0,
    orderBy: sort,
    orderDesc: sortDescValue,
    filter: searchValue,
  };

  const users = useProjectAllUsers(projectId, params);

  if (typeof users === "undefined") return <Spinner />;

  if (!users.length)
    return (
      <EmptyData
        title={
          searchValue
            ? ProjectStrings.USERS_NO_FOR_SEARCH
            : ProjectStrings.USERS_NO_IN_PROJECT_TITLE
        }
        description={
          searchValue ? null : ProjectStrings.USERS_NO_IN_PROJECT_DESC
        }
        actionTitle={
          searchValue ? null : ProjectStrings.USERS_NO_IN_PROJECT_ACTION_TITLE
        }
        onClick={onOpenCode}
        icon={<PeopleIcon />}
      />
    );

  return (
    <div className={classes.root}>
      {Object.entries(users).map(([key, user]) => (
        <div key={key} className={classes.cardContainer}>
          <ProjectTeamMemberCard
            userId={user.usersId}
            projectId={projectId}
            canReadPermissions={canReadPermissions}
            progress={progressId && progressId === user.usersId}
            onClick={onClick}
          />
        </div>
      ))}
    </div>
  );
};

export default ProjectTeamList;
