import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { matchPath } from "react-router";

import { Badge, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useUserHasNewEvent } from "../services/SystemEventService";

import {
  ROUTES,
  getOrganisationDashboardRoute,
  getOrganisationRootRoute,
} from "../route";
import { NavigationStrings } from "../strings";
import { backgroundColor } from "../configs/theme";
import { useCurrentOrganisation, useSystemAdmin } from "services/UserService";
import {
  ProjectIcon,
  ProfileIcon,
  NotificationsIcon,
  OrganisationIcon,
  SuperAdminIcon,
} from "assets/icons";

const useStyles = makeStyles((theme) => ({
  tab: {
    minHeight: 80,
    width: 80,
    minWidth: 80,
    textTransform: "capitalize",
  },
  mobileTabs: {
    "box-shadow": "0px 0px 10px rgba(0, 0, 0, 0.35)",
    backgroundColor: theme.palette.background.default,
  },
  label: {
    position: "absolute",
    bottom: 0,
  },
}));

const UnreadIcon = ({ icon }) => {
  const unread = useUserHasNewEvent();
  return (
    <Badge color="primary" badgeContent="" invisible={!unread}>
      {icon}
    </Badge>
  );
};

const NavigationBar = (props) => {
  const { mobile } = props;

  const history = useHistory();
  const classes = useStyles();
  const selectedOrganisationId = useCurrentOrganisation();
  const isSuperAdmin = useSystemAdmin();

  let tabsConfig = [
    {
      icon: <ProjectIcon />,
      label: NavigationStrings.PROJECTS,
      route: ROUTES.PROJECTS,
    },
    {
      icon: <ProfileIcon />,
      label: NavigationStrings.PROFILE,
      route: ROUTES.PROFILE,
    },
    {
      icon: <UnreadIcon icon={<NotificationsIcon />} />,
      label: NavigationStrings.NEWS,
      route: ROUTES.NEWS,
    },
  ];

  if (selectedOrganisationId) {
    tabsConfig = [
      ...tabsConfig,
      {
        icon: <OrganisationIcon />,
        label: NavigationStrings.ORGANISATION,
        route: getOrganisationDashboardRoute(selectedOrganisationId),
        rootRoute: getOrganisationRootRoute(selectedOrganisationId),
      },
    ];
  }

  if (isSuperAdmin) {
    tabsConfig = [
      ...tabsConfig,
      {
        icon: <SuperAdminIcon />,
        label: NavigationStrings.ADMIN,
        route: ROUTES.ADMIN_ORGANISATIONS,
        rootRoute: ROUTES.ADMIN,
      },
    ];
  }

  const currentTab = useMemo(
    () =>
      tabsConfig.findIndex((tab) =>
        matchPath(history.location.pathname, {
          path: tab?.rootRoute || tab.route,
        })
      ),
    [history.location.pathname, tabsConfig]
  );

  const onTabChange = (event, newTab) => {
    if (currentTab !== newTab) {
      history.push(tabsConfig[newTab].route);
    }
  };

  return (
    <Tabs
      className={clsx(mobile && classes.mobileTabs)}
      orientation={mobile ? "horizontal" : "vertical"}
      name="tab"
      value={currentTab >= 0 ? currentTab : false}
      onChange={onTabChange}
      variant={mobile ? "fullWidth" : "standard"}
      indicatorColor="primary"
      textColor="primary"
      TabIndicatorProps={{ style: { backgroundColor } }}
    >
      {tabsConfig.map((tab, index) => (
        <Tab
          className={classes.tab}
          key={tab.label}
          icon={tab.icon}
          label={
            currentTab === index ? (
              <div className={classes.label}>{tab.label}</div>
            ) : (
              ""
            )
          }
        />
      ))}
    </Tabs>
  );
};

export default NavigationBar;
