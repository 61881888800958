import React, { useMemo, useContext } from "react";
import AddToQueueOutlinedIcon from "@material-ui/icons/AddToQueueOutlined";

import { OrganisationStrings, ProjectStrings } from "strings";
import {
  useAdminOrganisationTabs,
  adminOrgsTabsConfig,
} from "./userAdminOrganisationTabs";
import { ORGANISATION_SORT_OPTION_FIELD } from "utils/organisationUtils";
import MainContext from "context/MainContext";
import { ROUTES } from "route";
import { PROJECT_SORT_OPTION_FIELD } from "utils/projectUtils";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";

export const useAdminOrganisationConfig = () => {
  const { sortFieldValue, sortDescValue, onSort, onSearch, onAction } =
    useContext(MainContext);

  const tabs = useAdminOrganisationTabs();

  const organisationSortOptions = useMemo(
    () =>
      Object.keys(ORGANISATION_SORT_OPTION_FIELD).map((key) => ({
        label: OrganisationStrings[key],
        desc: sortDescValue === null ? false : sortDescValue,
        selected:
          key ===
          (sortFieldValue || Object.keys(ORGANISATION_SORT_OPTION_FIELD)[0]),
        onClick: () => onSort(key),
      })),
    [onSort, sortDescValue, sortFieldValue]
  );

  const projectSortOptions = useMemo(
    () =>
      Object.keys(PROJECT_SORT_OPTION_FIELD).map((key) => ({
        label: ProjectStrings[key],
        desc: sortDescValue === null ? false : sortDescValue,
        selected:
          key === (sortFieldValue || Object.keys(PROJECT_SORT_OPTION_FIELD)[0]),
        onClick: () => onSort(key),
      })),
    [onSort, sortDescValue, sortFieldValue]
  );

  return [
    {
      path: ROUTES.ADMIN_ORGANISATIONS,
      appBar: {
        title: OrganisationStrings.ADMIN_TITLE_ORGANISATIONS,
        tabDefault: Object.keys(adminOrgsTabsConfig)[0],
        tabs,
        sort: organisationSortOptions,
        onSearch: (value) =>
          value && value.length > 0 ? onSearch(value) : onSearch(null),
      },
      actionButton: {
        actions: [
          {
            icon: <AddToQueueOutlinedIcon />,
            name: OrganisationStrings.ADD_DIALOG_CREATE,
            onClick: () =>
              onAction(GLOBAL_ACION_TYPES.ORGANISATION_CREATE, true),
          },
        ],
      },
    },
    {
      path: ROUTES.ADMIN_PROJECTS,
      appBar: {
        title: OrganisationStrings.ADMIN_TITLE_PROJECTS,
        tabDefault: Object.keys(adminOrgsTabsConfig)[1],
        tabs,
        sort: projectSortOptions,
        onSearch: (value) =>
          value && value.length > 0 ? onSearch(value) : onSearch(null),
      },
    },
  ];
};
