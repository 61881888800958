import React, {
  createContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import { debounce } from "services/UiService";
import { initValues } from "./initValues";

const MainContext = createContext(initValues);

export const MainContextProvider = ({ children }) => {
  const location = useLocation();

  const [sortFieldValue, setSortFieldValue] = useState(null);
  const [sortDescValue, setSortDescValue] = useState(null);
  const [searchValue, setSearchValue] = useState(null);

  const [actionType, setActionType] = useState(initValues.actionType);

  const resetData = () => {
    setSortFieldValue(null);
    setSortDescValue(null);
    setSearchValue(null);
  };

  useEffect(() => {
    resetData();
  }, [location]);

  const onSortHandle = useCallback(
    (opt) => {
      let sortDesc = sortDescValue;

      if (sortFieldValue === opt || sortFieldValue === null) {
        if (sortFieldValue === null) {
          setSortDescValue(false);
        } else {
          sortDesc = !sortDescValue;
          setSortDescValue(sortDesc);
        }
      }

      setSortFieldValue(opt);
    },
    [sortDescValue, sortFieldValue]
  );

  const onSearchHandle = debounce((value) => {
    if (searchValue === value) return;
    setSearchValue(value);
  }, 300);

  const onActionHandle = useCallback(
    (key, action) => {
      setActionType({ ...actionType, [key]: action });
    },
    [actionType]
  );

  const contextValues = useMemo(
    () => ({
      sortFieldValue,
      sortDescValue,
      searchValue,
      actionType,
      onSort: onSortHandle,
      onSearch: onSearchHandle,
      onAction: onActionHandle,
    }),
    [
      sortFieldValue,
      sortDescValue,
      searchValue,
      actionType,
      onSortHandle,
      onSearchHandle,
      onActionHandle,
    ]
  );

  return (
    <MainContext.Provider value={contextValues}>
      {children}
    </MainContext.Provider>
  );
};

export default MainContext;
