import React, { useState, useContext } from "react";
import {
  useParams,
  useHistory,
  generatePath,
  matchPath,
  useLocation,
} from "react-router-dom";

import { useMostRecentOrganisationsIds } from "services/UserService";
import {
  useOrganisationInfo,
  useUserOrganisationPermissions,
  useTotalUserOrganisationsCount,
} from "services/OrganisationService";
import {
  selectOrganisation,
  useCurrentOrganisation,
} from "services/UserService";
import { OrganisationsSelectDialog } from "ui/dialogs/organisationsSelectDialog";
import { OrganisationStrings } from "strings";
import { OrganisationItem } from "./menuItem/OrganisationItem";
import {
  MenuStyled,
  OrgCircleName,
  Header,
  OrgButton,
  MenuItemStyled,
  IconWrapper,
  SeeAllButton,
  OrgImage,
  LogoInMenuWrapper,
  Title,
  UserRole,
} from "./styles";
import { AddIcon } from "assets/icons";
import MainContext from "context/MainContext";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";

export const OrgMenu = ({ userId, path }) => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const { onAction } = useContext(MainContext);

  const selectedOrganisationId = useCurrentOrganisation();
  const orgIds = useMostRecentOrganisationsIds();
  const orgInfo = useOrganisationInfo(selectedOrganisationId);

  const { userPermissions, isSuperAdmin } = useUserOrganisationPermissions({
    userId,
    organisationId: selectedOrganisationId,
  });
  const [anchorOrg, setAnchorOrg] = useState(null);
  const [showOrgSelectDialog, setShowOrgSelectDialog] = useState(false);

  const orgsCount = useTotalUserOrganisationsCount(anchorOrg && userId);

  const onMenuCloseHandle = () => {
    setAnchorOrg(null);
  };

  const onSelectHandle = (organisation) => {
    const { organisationId } = organisation;
    if (selectedOrganisationId === organisationId) return;

    selectOrganisation({ organisationId });
    setShowOrgSelectDialog(false);
    onMenuCloseHandle();
    const match = matchPath(location.pathname, {
      path,
    });

    // not on organisation page
    if (!match?.params?.organisationId) return;

    // update the organisation id if on organisation page
    const pathToUpdate = generatePath(match.path, {
      ...params,
      organisationId,
    });

    history.push(pathToUpdate);
  };

  const onSelectMostResent = (organisation) => {
    onSelectHandle(organisation);
  };
  const onAnchorClickHandle = (e) => setAnchorOrg(e.currentTarget);
  const onOrgSelectDialogClose = () => setShowOrgSelectDialog(false);
  const onOrgSelectDialogOpen = () => setShowOrgSelectDialog(true);
  const onOrgCreateClickHandle = () => {
    onAction(GLOBAL_ACION_TYPES.ORGANISATION_CREATE, true);
    onMenuCloseHandle();
  };

  const renderOrgLogo = () => {
    if (!orgInfo || orgInfo.deleted) return null;
    if (orgInfo.imageURL)
      return (
        <LogoInMenuWrapper>
          <OrgImage src={orgInfo.imageURL} alt={orgInfo.name} />
        </LogoInMenuWrapper>
      );
    return orgInfo?.name[0];
  };

  // user is not in any organisations, don't show icon menu
  if (selectedOrganisationId === null) return null;
  return (
    <>
      <OrgButton onClick={onAnchorClickHandle}>{renderOrgLogo()}</OrgButton>
      <MenuStyled
        id="menuOrganisation"
        anchorEl={anchorOrg}
        open={!!anchorOrg}
        onClose={onMenuCloseHandle}
      >
        <Header>
          <OrgCircleName>
            {orgInfo?.imageURL && (
              <OrgImage src={orgInfo.imageURL} alt={orgInfo.name} />
            )}
          </OrgCircleName>
          <Title>{orgInfo?.name}</Title>
          <UserRole>{userPermissions?.name}</UserRole>
        </Header>
        {orgIds?.map((orgId) => (
          <OrganisationItem
            key={orgId}
            organisationId={orgId}
            selectedId={selectedOrganisationId}
            onSelect={onSelectMostResent}
          />
        ))}
        {!!orgIds?.length && orgsCount > 3 && (
          <SeeAllButton variant="text" onClick={onOrgSelectDialogOpen}>
            {OrganisationStrings.ORGANISATION_MENU_SEE_ALL}
          </SeeAllButton>
        )}
        {isSuperAdmin && (
          <MenuItemStyled className="custom" onClick={onOrgCreateClickHandle}>
            <IconWrapper>
              <AddIcon />
            </IconWrapper>
            {OrganisationStrings.ORGANISATION_MENU_CREATE}
          </MenuItemStyled>
        )}
      </MenuStyled>
      {anchorOrg && (
        <OrganisationsSelectDialog
          userId={userId}
          title={OrganisationStrings.ADMIN_TITLE_ORGANISATIONS}
          open={showOrgSelectDialog}
          currentOrganisationId={selectedOrganisationId}
          onSelect={onSelectHandle}
          onClose={onOrgSelectDialogClose}
        />
      )}
    </>
  );
};
