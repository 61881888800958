import { useMemo, useContext } from "react";

import { OrganisationStrings, ProjectStrings } from "strings";
import { useCurrentOrganisation } from "services/UserService";
import {
  useUserOrganisationPermissions,
  useUserHasConnectedDomain,
} from "services/OrganisationService";
import { TEAM_SORT_OPTION, GUEST_SORT_OPTION } from "utils/teamUtils";
import MainContext from "context/MainContext";
import { PROJECT_SORT_OPTION } from "./config";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";

export const useOrganisationActions = ({ organisationId, userId }) => {
  const { sortFieldValue, sortDescValue, onSort, onAction } =
    useContext(MainContext);

  const selectedOrganisationId = useCurrentOrganisation();
  const userHasConnectedDomains = useUserHasConnectedDomain(organisationId);
  const {
    canDeleteOrganisation,
    canWriteOrganisation,
    isSuperAdmin,
    isUserAdmin,
  } = useUserOrganisationPermissions({
    userId,
    organisationId,
  });

  const leaveOrganisation = {
    label: OrganisationStrings.OVERVIEW_MENU_LEAVE,
    onClick: () => onAction(GLOBAL_ACION_TYPES.ORGANISATION_LEAVE, true),
    disabled:
      (selectedOrganisationId === organisationId &&
        (userHasConnectedDomains === true || isUserAdmin === true)) ||
      (selectedOrganisationId !== organisationId && isSuperAdmin === true),
  };

  const deleteOrganisation = {
    label: OrganisationStrings.OVERVIEW_MENU_DELETE,
    onClick: () => onAction(GLOBAL_ACION_TYPES.ORGANISATION_DELETE, true),
    disabled: !canDeleteOrganisation,
    color: "error",
  };

  const inviteToOrganisation = {
    label: OrganisationStrings.OVERVIEW_MENU_INVITE,
    onClick: () => onAction(GLOBAL_ACION_TYPES.ORGANISATION_INVITE, true),
    disabled: !canWriteOrganisation,
  };

  const orgTeamSortOptions = useMemo(
    () =>
      TEAM_SORT_OPTION.map((opt) => ({
        label: OrganisationStrings[opt],
        desc: sortDescValue === null ? false : sortDescValue,
        selected: opt === (sortFieldValue || TEAM_SORT_OPTION[0]),
        onClick: () => onSort(opt),
      })),
    [onSort, sortDescValue, sortFieldValue]
  );

  const orgGuestSortOptions = useMemo(
    () =>
      GUEST_SORT_OPTION.map((opt) => ({
        label: OrganisationStrings[opt],
        desc: sortDescValue === null ? false : sortDescValue,
        selected: opt === (sortFieldValue || GUEST_SORT_OPTION[0]),
        onClick: () => onSort(opt),
      })),
    [onSort, sortDescValue, sortFieldValue]
  );

  const projectSortOptions = useMemo(
    () =>
      PROJECT_SORT_OPTION.map((opt) => ({
        label: ProjectStrings[opt],
        desc: sortDescValue === null ? false : sortDescValue,
        selected: opt === (sortFieldValue || PROJECT_SORT_OPTION[0]),
        onClick: () => onSort(opt),
      })),
    [onSort, sortDescValue, sortFieldValue]
  );

  return {
    leaveOrganisation,
    deleteOrganisation,
    inviteToOrganisation,
    orgTeamSortOptions,
    orgGuestSortOptions,
    projectSortOptions,
  };
};
