import React, { useMemo, useContext } from "react";
import { matchPath, useLocation } from "react-router-dom";
import AddToQueueOutlinedIcon from "@material-ui/icons/AddToQueueOutlined";
import QueuePlayNextOutlinedIcon from "@material-ui/icons/QueuePlayNextOutlined";

import { DefaultStrings, ProjectStrings } from "strings";
import { useProjectsTabs } from "./useProjectsTabs";
import { useCurrentOrganisation } from "services/UserService";
import { ROUTES } from "route";
import { PROJECT_SORT_OPTION_FIELD } from "utils/projectUtils";
import MainContext from "context/MainContext";
import { useUserOrganisationPermissions } from "services/OrganisationService";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";

export const useProjectsConfig = ({ userId }) => {
  const location = useLocation();
  const { sortFieldValue, sortDescValue, onSort, onSearch, onAction } =
    useContext(MainContext);
  const selectedOrganisationId = useCurrentOrganisation();
  const tabs = useProjectsTabs(selectedOrganisationId);
  const matchAllProjects = matchPath(location.pathname, {
    path: ROUTES.PROJECTS_ALL_PROJECTS,
  });
  const { canWriteProjects } = useUserOrganisationPermissions({
    userId,
    organisationId: selectedOrganisationId,
  });

  const projectSortOptions = useMemo(
    () =>
      Object.keys(PROJECT_SORT_OPTION_FIELD).map((key) => ({
        label: ProjectStrings[key],
        desc: sortDescValue === null ? false : sortDescValue,
        selected:
          key === (sortFieldValue || Object.keys(PROJECT_SORT_OPTION_FIELD)[0]),
        onClick: () => onSort(key),
      })),
    [onSort, sortDescValue, sortFieldValue]
  );

  return [
    {
      path: ROUTES.PROJECTS,
      appBar: {
        onSearch: (value) =>
          value && value.length > 0 ? onSearch(value) : onSearch(null),
        sort: projectSortOptions,
        title: DefaultStrings.TITLE,
        tabDefault:
          matchAllProjects && tabs.length > 1 ? tabs[1].key : tabs[0].key,
        tabs,
      },
      actionButton: {
        actions: [
          {
            icon: <AddToQueueOutlinedIcon />,
            name: ProjectStrings.ADD_PROJECT_DIALOG_CREATE,
            onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_CREATE, true),
            disabled: selectedOrganisationId && !canWriteProjects,
          },
          {
            icon: <QueuePlayNextOutlinedIcon />,
            name: ProjectStrings.ADD_PROJECT_DIALOG_JOIN,
            onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_JOIN, true),
          },
        ],
      },
    },
  ];
};
