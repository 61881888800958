const STRINGS = {
  LIST_NO_ORGANISATIONS: "No organisations",
  LIST_NO_FOR_SEARCH: "No organisations found",

  DIALOG_NAME: "Organisation Name",
  DIALOG_ERROR: "Something went wrong with users invite",

  ADD_DIALOG_CREATE: "Add an organisation",
  ADD_DIALOG_TITLE: "Create Organisation",
  ADD_DIALOG_NAME: "Name",
  ADD_DIALOG_LOGO: "Logo",
  ADD_DIALOG_DOMAIN: "Enter a domain",
  ADD_DIALOG_DOMAIN_DESC:
    "Matching users will automatically be invited to join your organisation",
  ADD_DIALOG_ADD_DOMAINS: "Add Connected Domains",
  ADD_DIALOG_ADD_DOMAINS_INFO:
    "Connected domains determine which MyPlayer users are part of your organisation",
  ADD_DIALOG_DESCRIPTION: "Create a new organisation",
  ADD_ORGANISATION_SUCCESS: "Organisation successfully created",
  ADD_ORGANISATION_DOMAIN_EXISTS:
    'Organisation with "{domain}" domain already exists',
  ADD_ORGANISATION_DOMAIN_ALREADY_ON_LIST:
    '"{domain}" domain already on your list',
  ADD_ORGANISATION_DOMAIN_CHECK_ERROR:
    "Something went wrong with domain cheking",
  ADD_ORGANISATION_ERROR: "Something went wrong with adding organisation",

  UPDATE_ORGANISATION_SUCCESS: "Organisation successfully updated",
  UPDATE_ORGANISATION_ERROR: "Something went wrong with updating organisation",

  TAB_TITLE_DASHBOARD: "Dashboard",
  TAB_TITLE_PROJECTS: "Projects",
  TAB_TITLE_TEAM: "Team",
  TAB_TITLE_GUESTS: "Guests",
  TAB_TITLE_ACTIVITY: "Activity",
  TAB_TITLE_SETTINGS: "Settings",

  ADMIN_TITLE_ORGANISATIONS: "Organisations",
  ADMIN_TITLE_PROJECTS: "Projects",
  ORGANISATION_MENU_SEE_ALL: "See all",
  ORGANISATION_MENU_CREATE: "Create",

  OVERVIEW_MENU_DELETE: "Delete Organisation",
  OVERVIEW_MENU_INVITE: "Invite",
  OVERVIEW_MENU_LEAVE: "Leave Organisation",
  OVERVIEW_LEAVE_CONFIRM: "Are you sure you want to leave this organisation?",
  OVERVIEW_LEFT: "You left the organisation",
  OVERVIEW_DELETE_CONFIRM: "Are you sure you want to delete this organisation?",
  OVERVIEW_DELETED: "Organisation deleted!",

  OVERVIEW_TEAM_STAT_TITLE: "Team",
  OVERVIEW_TEAM_STAT_TOTAL: "Total",
  OVERVIEW_TEAM_STAT_ADMINS: "Admins",
  OVERVIEW_TEAM_STAT_MEMBERS: "Members",

  OVERVIEW_PROJECTS_STAT_TITLE: "Projects",
  OVERVIEW_PROJECTS_STAT_TOTAL: "Total",
  OVERVIEW_PROJECTS_STAT_ACTIVE: "Active",
  OVERVIEW_PROJECTS_STAT_INACTIVE: "Inactive",
  OVERVIEW_PROJECTS_STAT_DEVELOPMENT: "Development",

  OVERVIEW_GUESTS_STAT_TITLE: "Guests",
  OVERVIEW_GUESTS_STAT_TOTAL: "Total",
  OVERVIEW_DEVICES_STAT_TITLE: "Devices",
  OVERVIEW_SMART_TAGS_STAT_TITLE: "Smart Tags",

  SETTINGS_GUEST_ACCESS_TITLE: "Project Guest Access",
  SETTINGS_GUEST_ACCESS_SELECT_TITLE: "Access",
  SETTINGS_GUEST_ACCESS_SELECT_DESC:
    "Choose whether your organisation's projects can be accessed by external guests.",
  SETTINGS_GUEST_ACCESS_INFO:
    "Guest Access determines if your organisation and its projects are accessible by guests of your organisation",
  SETTINGS_GUEST_ACCESS_UPDATE_ERROR:
    "Something went wrong with updating guest access",

  SETTINGS_CONNECTED_DOMAINS_TITLE: "Connected Domains",
  SETTINGS_CONNECTED_DOMAINS_INFO:
    "Connected domains decide who belongs to your organisation and who is guest. Contact team@myplayer.io to update your domain information",

  SETTINGS_PROJECT_ACCESS_TITLE: "Project Access",
  SETTINGS_PROJECT_ACCESS_SELECT_TITLE: "Access",
  SETTINGS_PROJECT_ACCESS_SELECT_DESC: {
    title: "Choose how projects in your organisation can be joined:",
    list: [
      { key: "Private:", value: "Projects require a share code to join." },
      {
        key: "Open:",
        value:
          "Projects are available for all users in your organisation to join.",
      },
    ],
  },
  SETTINGS_PROJECT_ACCESS_INFO:
    "Project Access defines whether projects in your organisation are private, requiring a share code, or open to anyone in the organisation to join",
  SETTINGS_PROJECT_ACCESS_UPDATE_ERROR:
    "Something went wrong with updating project access",

  SETTINGS_PROJECT_JOIN_APPROVAL_TITLE: "Project Join Approval",
  SETTINGS_PROJECT_JOIN_APPROVAL_SELECT_TITLE: "Method",
  SETTINGS_PROJECT_JOIN_APPROVAL_SELECT_DESC: {
    title: "Select the default method for joining new projects:",
    list: [
      {
        key: "Auto:",
        value: "Users can access the project immediately upon joining.",
      },
      {
        key: "Required:",
        value:
          "Users will be added to a list for approval before they can access the project.",
      },
    ],
  },
  SETTINGS_PROJECT_JOIN_APPROVAL_INFO:
    "Determines the default project joining process for all new projects. Auto means that people will automatically join when using share code or via all projects. Required means that all users will go into the awaiting approval list for the project",
  SETTINGS_PROJECT_JOIN_APPROVAL_UPDATE_ERROR:
    "Something went wrong with updating project join approval",

  SETTINGS_PROJECT_DEFAULT_ROLE_TITLE: "Default Project Role",
  SETTINGS_PROJECT_DEFAULT_ROLE_SELECT_TITLE: "Role",
  SETTINGS_PROJECT_DEFAULT_ROLE_SELECT_DESC: {
    title: "Choose the default role assigned to users when they join projects:",
    list: [
      {
        key: "Owner:",
        value:
          "Has full control over the project, including all settings and management tasks.",
      },
      {
        key: "Editor:",
        value:
          "Can manage devices, media, and analytics, but does not have full administrative control.",
      },
      {
        key: "Viewer:",
        value: "Can only view media and analytics without making any changes.",
      },
    ],
  },
  SETTINGS_PROJECT_DEFAULT_ROLE_INFO:
    "Determines the default project role users will have when joining projects via the all projects tab",
  SETTINGS_PROJECT_DEFAULT_ROLE_UPDATE_ERROR:
    "Something went wrong with updating default project role",

  ORGANISATION_USER_ROLE_TITLE: "User Organisation Role",

  SETTINGS_ORGANISATION_DEFAULT_ROLE_TITLE: "Default Organisation Role",
  SETTINGS_ORGANISATION_DEFAULT_ROLE_SELECT_TITLE: "Role",
  SETTINGS_ORGANISATION_DEFAULT_ROLE_SELECT_DESC: {
    title:
      "Choose the default role assigned to users when they join the organisation:",
    list: [
      {
        key: "Administrator:",
        value:
          "Can do everything, including managing the organisation and viewing all projects.",
      },
      {
        key: "Creator:",
        value: "Can create projects in the organisation.",
      },
      {
        key: "Viewer:",
        value: "Can join projects.",
      },
    ],
  },
  SETTINGS_ORGANISATION_DEFAULT_ROLE_INFO:
    "The Default Organisation Role determines the initial role assigned to users when they join the organisation",
  SETTINGS_ORGANISATION_DEFAULT_ROLE_UPDATE_ERROR:
    "Something went wrong with updating default organisation role",

  SORT_OPTION_MOSTRECENT: "Most Recent",
  SORT_OPTION_NAME: "Name",
  SORT_OPTION_ROLE: "Role",
  SORT_OPTION_DATE: "Date",
  SORT_OPTION_JOIN_STATUS: "Join Status",

  JOIN_LOADING_TITLE: "Join an organisation",
  JOIN_LOADING_DESCRIPTION: "You are joining...",
  JOIN_ACCEPT_SUCCESS: "Organisation successfully joined",
  JOIN_DECLINE_SUCCESS: "You have declined to join the organisation",
  ORGANISATION_TO_JOIN_TITLE: "Organisation To Join",

  USERS_NO_IN_ORGANISATION: "No team members in your organisation",
  USERS_NO_IN_ORGANISATION_DESC: "Team members are part of your organisation",
  USERS_NO_FOR_SEARCH: "No team members found",

  USERS_NO_GUESTS_IN_ORGANISATION: "No guests in your organisation",
  USERS_NO_GUESTS_IN_ORGANISATION_DESC:
    "Guests have access to some of your organisation projects but are not part of your organisation",
  USERS_NO_GUESTS_FOR_SEARCH: "No guests found",

  INVITE_TITLE: "Invite Team Members",
  INVITE_BUTTON_INFO: "Invite team members",
  INVITE_DESC: "Invite members to you organisation by their email address",
  INVITE_EMAIL_TEXTFIELD: "Enter an email address",
  INVITE_ROLE_SELECT: "Role",
  INVITE_USER_EMAIL_EXISTS:
    'User with "{email}" email already exists in your organisation',
  INVITE_USER_INVITE_EXISTS: "User already invited to your organisation",
  INVITEUSER_EMAIL_ALREADY_ON_LIST: '"{email}" email already on your list',
  INVITE_SEND_SUCCESS: "User invitation successfully sent",
  INVITE_SEND_ALL_SUCCESS: "Users invitations successfully sent",
  INVITE_RESEND_SUCCESS: "Invitation successfully sent",
  INVITE_RESEND_ALL_SUCCESS: "All invitations successfully sent",
  INVITE_USER_ROLE_UPDATE_SUCCESS: "User role successfully updated",
  INVITE_DELETE_SUCCESS: "Pending invite successfully deleted",
  INVITE_DELETE_ALL_SUCCESS: "All pending invitations successfully deleted",
  INVITES_PENDING_TITLE: "Pending Team Invitations",
  INVITES_PEDING_INFO: "These invitations are pending to join the organisation",
  INVITES_DELETE_ALL: "Delete All",
  INVITES_RESEND_INVITATIONS: "Resend Invitations",
  INVITES_RESEND_INVITATIONS_CONFIRM_TITLE: "Resend Invitations",
  INVITES_RESEND_INVITATIONS_CONFIRM_DESC:
    "Are you sure you want to resend all pending invitations?",
  INVITES_DELETE_ALL_CONFIRM_TITLE: "Delete Invitations",
  INVITES_DELETE_ALL_CONFIRM_DESC:
    "Are you sure you want to delete all pending invitations to the organisation?",
  INVITES_DELETE_CONFIRM_TITLE: "Delete Invitation",
  INVITES_DELETE_CONFIRM_DESC:
    "Are you sure you want to delete this pending invite?",
  INVITES_NO_INVITES: "No pending invites",
  INVITES_NO_FOR_SEARCH: "No pending invites found",
  CARD_ITEM_CURRENT: "Current",
  CARD_ITEM_SELECT: "Select",
  CARD_ITEM_VIEW: "View",

  CHATROOM_NO_MESSAGE_TITLE: "No activity in your organisation",
  CHATROOM_NO_MESSAGE_DESC: "There has been no activity in your organisation",

  PENDING_INVITES_BUTTON_TITLE: "Manage",
  PENDING_INVITES_HAS_PENDING_INVITE: "has invitation pending",
  PENDING_INVITES_OTHER_HAVE_PENDING_INVITE: "other have invitations pending",
  PENDING_INVITES_OTHERS_HAVE_PENDING_INVITE: "others have invitations pending",
  PENDING_INVITES_ONE: "pending invitation",
  PENDING_INVITES_MANY: "pending invitations",
  PENDING_INVITES_RESEND_BUTTON_TITLE: "Resend",

  GUEST_ACCESS_ENABLED: "Enabled",
  GUEST_ACCESS_DISABLED: "Disabled",
  PROJECT_ACCESS_OPEN: "Open",
  PROJECT_ACCESS_PRIVATE: "Private",
  PROJECT_JOIN_APPROVAL_AUTO: "Auto",
  PROJECT_JOIN_APPROVAL_REQUIRED: "Required",

  TEAM_MEMBER_REMOVE_CONFIRM_TITLE: `Remove "{name}"`,
  TEAM_MEMBER_REMOVE_CONFIRM_DESC: `Are you sure you want to delete "{name}" from your organisation?`,

  NO_PART_OF_ANY_ORGANISATION: "You are not part of any organisation",
};

export default STRINGS;
