import { useHistory } from "react-router-dom";

import { ProjectStrings } from "strings";
import { ROUTES } from "route";

export const useProjectsTabs = (selectedOrganisationId) => {
  const history = useHistory();
  const tabsProjectsConfig = (selectedOrganisationId) => {
    const tabs = {
      MY_PROJECTS: {
        key: "MY_PROJECTS",
        label: ProjectStrings.TAB_TITLE_MY_PROJECTS,
        path: ROUTES.PROJECTS,
      },
    };
    if (selectedOrganisationId) {
      tabs["ALL_PROJECTS"] = {
        key: "ALL_PROJECTS",
        label: ProjectStrings.TAB_TITLE_ALL_PROJECTS,
        path: ROUTES.PROJECTS_ALL_PROJECTS,
      };
    }
    return tabs;
  };

  return Object.entries(tabsProjectsConfig(selectedOrganisationId)).map(
    ([key, cfg]) => ({
      key,
      label: cfg.label,
      onClick: () => {
        history.push(cfg.path);
      },
    })
  );
};
