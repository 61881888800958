import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import WorkspacesOutlinedIcon from "@material-ui/icons/WorkspacesOutlined";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { useSnackbar } from "notistack";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

import { useMobileLayout } from "hooks/uiHooks";
import {
  inviteUsers,
  checkUserEmail,
  useOrganisationInfo,
} from "services/OrganisationService";
import { useTeamOrganisationPermissionsOptions } from "services/PermissionService";
import { Select, TextInput, Chip } from "components";
import { useStyles } from "./style";
import { OrganisationStrings, DefaultStrings } from "strings";
import {
  ORGANISATION_PERMISSIONS,
  getOrgPermissionName,
} from "utils/permissionUtils";
import { isEmailValid, generateColorByFirstLetter } from "utils/generalUtils";
import LienearSpinner from "ui/LinearSpinner";

export const InviteUserDialog = ({ open, organisationId, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const orgInfo = useOrganisationInfo(organisationId);
  const permissionsOptions = useTeamOrganisationPermissionsOptions();

  const [invites, setInvites] = useState([]);
  const [emailValue, setEmailValue] = useState(null);
  const [roleValue, setRoleValue] = useState(ORGANISATION_PERMISSIONS.VIEWER);
  const [loading, setLoading] = useState(false);
  const [emailCheckLoading, setEmailCheckLoading] = useState(false);
  const [isEmailValueValid, setIsEmailValueValid] = useState(false);
  const [isInValid, setIsInValid] = useState(false);

  useEffect(() => {
    if (orgInfo && orgInfo.defaultOrganisationRole) {
      setRoleValue(orgInfo.defaultOrganisationRole);
    }
  }, [orgInfo]);

  const onCloseHandle = () => {
    setEmailValue(null);
    setInvites([]);
    onClose();
  };

  const onSendHandle = async () => {
    try {
      setLoading(true);
      await inviteUsers({ organisationId, invites });
      enqueueSnackbar(
        invites.length > 1
          ? OrganisationStrings.INVITE_SEND_ALL_SUCCESS
          : OrganisationStrings.INVITE_SEND_SUCCESS,
        {
          variant: "success",
        }
      );
      setLoading(false);
      onCloseHandle();
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(OrganisationStrings.DIALOG_ERROR, {
        variant: "error",
      });
    }
  };

  const checkEmail = async (emailToCheck) => {
    const invitesToUpdate = [...invites];
    invitesToUpdate.push({
      email: emailToCheck,
      permissionsKey: roleValue,
      color: generateColorByFirstLetter(),
    });

    const emailAddedToList = invites.find((d) => d.email === emailToCheck);
    // check if email already on the list
    if (emailAddedToList) {
      enqueueSnackbar(
        OrganisationStrings.INVITEUSER_EMAIL_ALREADY_ON_LIST.replace(
          "{email}",
          emailToCheck
        ),
        {
          variant: "warning",
        }
      );
      return;
    }

    try {
      // check if user with that email already in the organisation
      setEmailCheckLoading(true);
      const res = await checkUserEmail({
        email: emailToCheck,
        organisationId,
      });
      setEmailCheckLoading(false);

      const { isUserExists, isInviteExisting } = res.result;

      if (isUserExists) {
        setIsInValid(true);
        enqueueSnackbar(
          OrganisationStrings.INVITE_USER_EMAIL_EXISTS.replace(
            "{email}",
            emailValue
          ),
          {
            variant: "warning",
          }
        );
      } else if (isInviteExisting) {
        setIsInValid(true);
        enqueueSnackbar(OrganisationStrings.INVITE_USER_INVITE_EXISTS, {
          variant: "warning",
        });
      } else {
        setInvites(invitesToUpdate);
        setEmailValue(null);
        setIsInValid(false);
      }
    } catch (err) {
      setEmailCheckLoading(false);
      console.error(err);
      enqueueSnackbar(DefaultStrings.ERROR_MSG, {
        variant: "error",
      });
    }
  };

  const onInputChangeHandle = async (ev) => {
    const { name, value } = ev.target;

    setIsInValid(false);

    if (name === "email") {
      setEmailValue(value);

      // check is email valid
      const isValid = isEmailValid(value);
      setIsEmailValueValid(isValid);

      // email invalid
      if (!isValid) return;

      if (ev.key === "Enter") {
        checkEmail(value);
      }
    } else if (name === "role") {
      setRoleValue(value);
    }
  };
  const onClickAddEmailHandle = () => {
    checkEmail(emailValue);
  };

  const onDeleteHandle = (idx) => () => {
    let toUpdate = [...invites];
    toUpdate.splice(idx, 1);
    setInvites(toUpdate);
  };

  return (
    <Dialog
      fullWidth
      fullScreen={mobile}
      maxWidth="sm"
      open={open}
      onClose={onCloseHandle}
    >
      {(emailCheckLoading || loading) && <LienearSpinner />}
      <DialogTitle className={classes.title} disableTypography>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <WorkspacesOutlinedIcon style={{ marginRight: "16px" }} />
          <Typography variant="h6">
            {OrganisationStrings.INVITE_TITLE}
          </Typography>
        </Box>
        <Typography variant="body2">
          {OrganisationStrings.INVITE_DESC}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box m={1}>
          <TextInput
            name="email"
            label={OrganisationStrings.INVITE_EMAIL_TEXTFIELD}
            value={emailValue || ""}
            isInValid={isInValid}
            endActionIcon={isEmailValueValid && <AddOutlinedIcon />}
            onClick={onClickAddEmailHandle}
            onChange={onInputChangeHandle}
            onKeyDown={onInputChangeHandle}
          />
          <Box mt={1}>
            <Select
              name="role"
              label={OrganisationStrings.INVITE_ROLE_SELECT}
              value={roleValue}
              options={permissionsOptions}
              onChange={onInputChangeHandle}
              fullWidth
            />
          </Box>
          <Box mt={1}>
            {invites.map(({ email, permissionsKey, color }, idx) => (
              <Chip
                key={email}
                title={email}
                description={getOrgPermissionName(
                  permissionsKey,
                  permissionsOptions
                )}
                startIcon={
                  <div
                    className={classes.chipStartIcon}
                    style={{ backgroundColor: color }}
                  >
                    {email[0].toUpperCase()}
                  </div>
                }
                endIcon={
                  <DeleteOutlineOutlinedIcon className={classes.chipEndIcon} />
                }
                onClick={onDeleteHandle(idx)}
              />
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <IconButton
          onClick={onSendHandle}
          disabled={loading || !invites.length}
        >
          <SendOutlinedIcon />
        </IconButton>
        <IconButton onClick={onClose} disabled={loading}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};
