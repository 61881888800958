const ROUTES = {
  // un-protected routes
  DOWNLOAD: "/download",

  // Installer routes
  UPDATE_DEVICE: "/inst/:deviceId/update",

  // general user routes
  HOME: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  MEDIA: "/media/:linkId",

  ADMIN: "/admin",
  ADMIN_ORGANISATIONS: "/admin/organisations",
  ADMIN_PROJECTS: "/admin/projects",

  PROJECTS: "/projects",
  PROJECTS_ALL_PROJECTS: "/projects/all",
  PROJECT_JOIN: "/projects/join/:code",
  JOIN: "/join/:code",
  PROJECT_NEW: "/projects/new",
  PROFILE: "/profile",
  NEWS: "/news",

  ORGANISATION_JOIN: "/organisation/join/:code",

  // admin routes
  // RECOVER: "/recover",
  REMOTE_MAIN: "/remote",
  REMOTE_DEVICE: "/remote/:deviceId",

  // project page tabs
  PROJECT_ROOT: "/project/:projectId",
  PROJECT_OVERVIEW: "/project/:projectId/overview",
  PROJECT_MEDIA: "/project/:projectId/media",
  PROJECT_MEDIA_EDIT_TAB: "/project/:projectId/media/edit/:tab",
  PROJECT_MEDIA_PREVIEW: "/project/:projectId/media/preview/:draftId",
  PROJECT_MEDIA_MESSAGES: "/project/:projectId/media/messages",
  PROJECT_DEVICES: "/project/:projectId/devices",
  PROJECT_DEVICE_SINGLE: "/project/:projectId/device/:deviceId",
  PROJECT_DEVICE_TAB: "/project/:projectId/device/:deviceId/:tab",
  PROJECT_ANALYTICS: "/project/:projectId/analytics",
  PROJECT_TEAM: "/project/:projectId/team",
  PROJECT_TEAM_SHARE_TAB: "/project/:projectId/team/:tab",

  // organisation page tabs
  ORGANISATION_ROOT: "/organisation/:organisationId",
  ORGANISATION_DASHBOARD: "/organisation/:organisationId/dashboard",
  ORGANISATION_PROJECTS: "/organisation/:organisationId/projects",
  ORGANISATION_TEAM: "/organisation/:organisationId/team",
  ORGANISATION_GUESTS: "/organisation/:organisationId/guests",
  ORGANISATION_ACTIVITY: "/organisation/:organisationId/activity",
  ORGANISATION_SETTINGS: "/organisation/:organisationId/settings",
};

export default ROUTES;
