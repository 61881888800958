import { makeStyles } from "@material-ui/core/styles";

import { navigationBarHeight, navigationBarWidth } from "ui/pageLayout/config";

export const useStyles = makeStyles({
  root: ({ size }) => ({
    height: size.height,
    display: "flex",
    flexDirection: "column",
  }),
  main: {
    display: "flex",
    height: 1,
    flexGrow: 1,
  },
  content: ({ mobile, noPath }) => {
    const res = {
      width: "100%",
    };
    if (noPath) return res;
    if (mobile) {
      res.marginBottom = navigationBarHeight;
    } else {
      res.paddingLeft = navigationBarWidth;
    }
    return res;
  },
});
