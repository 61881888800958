export const ORGANISATION_PERMISSIONS = {
  CREATOR: "creator_default",
  ADMIN: "admin_default",
  VIEWER: "viewer_default",
  GUEST: "guest_default",
};

export const PROJECT_PERMISSIONS = {
  ADMIN: "admin_default", // Owner
  CREATOR: "creator_default", // OBSOLETE
  MANAGER: "manager_default", // Editor
  VIEWER: "viewer_default", // Viewer
  USER: "user_default", // OBSOLETE
};

export const getOrgPermissionName = (permissionsKey, options) => {
  if (!permissionsKey) {
    console.error("No permission key provided");
    return "";
  }

  const idx = options.findIndex((opt) => opt.value === permissionsKey);

  if (idx === -1) return "Not set";

  return options[idx].title;
};

export const getProjectPermissionName = (permissionsKey, options) => {
  if (!permissionsKey) {
    console.error("No permission key provided");
    return "";
  }

  const idx = options.findIndex((opt) => opt.value === permissionsKey);

  if (idx === -1) return "Not set";

  return options[idx].title;
};

const PERMISSION_KEYS = {
  ANALYTICS: "analytics",
  CODE: "code", // project share code
  CONTENT: "content", // project media content
  DEVICE_DEPLOYMENT: "deviceDeployment", // deviceDeployment is not used
  DEVICE_EXTRA: "deviceExtra",
  DEVICES: "devices",
  PROJECT: "project",
  PROJECTS: "projects",
  ORGANISATION: "organisation",
  PROJECT_APPROVALS: "projectApprovals",
  PROJECT_EXTRA: "projectExtra",
  PROJECT_MEDIA: "projectMedia",
  USER_PERMISSIONS: "userPermissions",
  USERS: "users",
  MESSAGE: "message",
};

const PERMISSION_ACTIONS = {
  READ: "read",
  WRITE: "write",
  DELETE: "delete",
};

// return undefined if permissions is undefined (loading)
const canPerformAction = (permissions, key, action) =>
  permissions?.[key]?.[action];

export const canReadAnalytics = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.ANALYTICS,
    PERMISSION_ACTIONS.READ
  );

export const canWriteAnalytics = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.ANALYTICS,
    PERMISSION_ACTIONS.WRITE
  );

export const canReadCode = (permissions) =>
  canPerformAction(permissions, PERMISSION_KEYS.CODE, PERMISSION_ACTIONS.READ);

export const canWriteCode = (permissions) =>
  canPerformAction(permissions, PERMISSION_KEYS.CODE, PERMISSION_ACTIONS.WRITE);

export const canReadContent = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.CONTENT,
    PERMISSION_ACTIONS.READ
  );

export const canWriteContent = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.CONTENT,
    PERMISSION_ACTIONS.WRITE
  );

export const canReadDevices = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.DEVICES,
    PERMISSION_ACTIONS.READ
  );

export const canWriteDevices = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.DEVICES,
    PERMISSION_ACTIONS.WRITE
  );

export const canReadDeviceExtra = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.DEVICE_EXTRA,
    PERMISSION_ACTIONS.READ
  );

export const canWriteDeviceExtra = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.DEVICE_EXTRA,
    PERMISSION_ACTIONS.WRITE
  );

export const canDeleteProject = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.PROJECT,
    PERMISSION_ACTIONS.DELETE
  );

export const canReadProjectMedia = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.PROJECT_MEDIA,
    PERMISSION_ACTIONS.READ
  );

export const canWriteProjectMedia = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.PROJECT_MEDIA,
    PERMISSION_ACTIONS.WRITE
  );

export const canReadProject = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.PROJECT,
    PERMISSION_ACTIONS.READ
  );

export const canWriteProject = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.PROJECT,
    PERMISSION_ACTIONS.WRITE
  );

export const canReadProjects = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.PROJECTS,
    PERMISSION_ACTIONS.READ
  );

export const canWriteProjects = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.PROJECTS,
    PERMISSION_ACTIONS.WRITE
  );

export const canReadProjectApprovals = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.PROJECT_APPROVALS,
    PERMISSION_ACTIONS.READ
  );

export const canWriteProjectApprovals = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.PROJECT_APPROVALS,
    PERMISSION_ACTIONS.WRITE
  );

export const canReadProjectExtra = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.PROJECT_EXTRA,
    PERMISSION_ACTIONS.READ
  );

export const canWriteProjectExtra = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.PROJECT_EXTRA,
    PERMISSION_ACTIONS.WRITE
  );

export const canReadUserPermissions = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.USER_PERMISSIONS,
    PERMISSION_ACTIONS.READ
  );

export const canWriteUserPermissions = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.USER_PERMISSIONS,
    PERMISSION_ACTIONS.WRITE
  );

export const canReadUsers = (permissions) =>
  canPerformAction(permissions, PERMISSION_KEYS.USERS, PERMISSION_ACTIONS.READ);

export const canDeleteUsers = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.USERS,
    PERMISSION_ACTIONS.DELETE
  );

export const canReadShowroom = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.PROJECT_EXTRA,
    PERMISSION_ACTIONS.READ
  );

export const canWriteShowroom = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.PROJECT_EXTRA,
    PERMISSION_ACTIONS.WRITE
  );

export const canWriteOrganisation = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.ORGANISATION,
    PERMISSION_ACTIONS.WRITE
  );

export const canReadOrganisation = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.ORGANISATION,
    PERMISSION_ACTIONS.READ
  );

export const canDeleteOrganisation = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.ORGANISATION,
    PERMISSION_ACTIONS.DELETE
  );

export const canWriteMessages = (permissions) =>
  canPerformAction(
    permissions,
    PERMISSION_KEYS.MESSAGE,
    PERMISSION_ACTIONS.WRITE
  );
