export const TEAM_SORT_OPTION_FIELD = {
  SORT_OPTION_NAME: "name",
  SORT_OPTION_MOSTRECENT: "creation_date",
  SORT_OPTION_ROLE: "permissionsKey",
};

export const TEAM_SORT_OPTION = Object.keys(TEAM_SORT_OPTION_FIELD);

export const GUEST_SORT_OPTION = Object.keys(TEAM_SORT_OPTION_FIELD).slice(
  0,
  2
);

export const teamContainsFilter = ({ user, filter }) => {
  if (!user || !filter) return true;
  if (filter.length === 0) return true;

  const filterLower = filter.toLowerCase();
  const nameLower = user.name && user.name.toLowerCase();
  const permissionsKeyLower =
    user.permissionsKey && user.permissionsKey.toLowerCase();
  return (
    (nameLower && nameLower.includes(filterLower)) ||
    (permissionsKeyLower && permissionsKeyLower.includes(filterLower))
  );
};

export const isCodeMutable = (info) => info && info.immutable === false;
