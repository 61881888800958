export const ORG_TABS_KEYS = {
  DASHBOARD: "DASHBOARD",
  PROJECTS: "PROJECTS",
  TEAM: "TEAM",
  GUESTS: "GUESTS",
  ACTIVITY: "ACTIVITY",
  SETTINGS: "SETTINGS",
};

export const PROJECT_SORT_OPTION_FIELD = {
  SORT_OPTION_NAME: "name",
  SORT_OPTION_MOSTRECENT: "modified_date",
  SORT_OPTION_BRAND: "brand",
  SORT_OPTION_DATE: "creation_date",
};

export const PROJECT_SORT_OPTION = Object.keys(PROJECT_SORT_OPTION_FIELD);

export const navigationBarHeight = "80px";
export const navigationBarWidth = "80px";
