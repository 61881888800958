import React, { useEffect, useState, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ViewCarouselOutlinedIcon from "@material-ui/icons/ViewCarouselOutlined";
import SearchIcon from "@material-ui/icons/Search";

import { getAllProjects } from "services/ApiService";
import { useMobileLayout } from "hooks/uiHooks";
import { useStyles } from "./style";
import { DefaultStrings, ProjectStrings } from "strings";
import Spinner from "ui/Spinner";
import ProjectCard from "ui/cards/projectCard/ProjectCard";
import InfiniteScroll from "ui/InfiniteScroll";
import { BELONGING_TO_OPTIONS, BELONGING_TO_VALUE } from "./config";
import { EmptyData } from "ui/emptyData";
import { getProjectOverviewRoute } from "route";
import { useProjectUsersLastUpdate } from "services/UserService";
import MainContext from "context/MainContext";
import { PROJECT_SORT_OPTION_FIELD } from "utils/projectUtils";

const Projects = () => {
  const history = useHistory();
  const [startAfter, setStartAfter] = useState();
  const [projectsIds, setProjectsIds] = useState();
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [belongingToOrg, setBelongingToOrg] = useState(BELONGING_TO_VALUE.ALL);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [fetchedAt, setFetchedAt] = useState();

  const projectUsersLastUpdate = useProjectUsersLastUpdate();
  const [loading, setLoading] = useState(false);

  const { sortFieldValue, sortDescValue, searchValue } =
    useContext(MainContext);

  const mobile = useMobileLayout();
  const classes = useStyles({ mobile, small: false });

  const resetData = () => {
    setIsFetchedAll(false);
    setIsRequestSent(true);
    setStartAfter();
  };

  const getParams = useCallback((params) => {
    const {
      sortDesc,
      sortValue,
      lastKey,
      belongingToOrg: belongingTo,
    } = params;
    const orderBy =
      sortValue && sortValue in PROJECT_SORT_OPTION_FIELD
        ? PROJECT_SORT_OPTION_FIELD[params.sortValue]
        : PROJECT_SORT_OPTION_FIELD.SORT_OPTION_NAME;

    return {
      limit: 10,
      orderBy,
      searchValue: params.searchValue,
      lastKey,
      orderDesc: sortDesc,
      belongingToOrg: belongingTo,
    };
  }, []);

  const fetchData = useCallback(
    async (params) => {
      const requestParams = getParams({
        belongingToOrg,
        sortDesc: sortDescValue,
        sortValue: sortFieldValue,
        searchValue,
        ...params,
      });

      setLoading(true);

      try {
        const response = await getAllProjects(requestParams);
        const items = response.result.items;

        setFetchedAt(response.result.fetchedAt);

        if (items.length === 0) {
          setIsFetchedAll(true);
          setIsRequestSent(false);
        }

        if (params?.lastKey && !isRequestSent) {
          setProjectsIds([...projectsIds, ...items]);
        } else {
          setProjectsIds(items);
          setIsRequestSent(false);
        }

        if (items.length) {
          setStartAfter(items[items.length - 1]);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    },
    [
      belongingToOrg,
      getParams,
      isRequestSent,
      projectsIds,
      searchValue,
      sortDescValue,
      sortFieldValue,
    ]
  );

  useEffect(() => {
    // initial request on page loaded
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortFieldValue, sortDescValue, searchValue]);

  useEffect(() => {
    // do request if project users last update date changed
    if (projectUsersLastUpdate > fetchedAt) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUsersLastUpdate, fetchedAt]);

  const configCard = {
    showParticipants: true,
    showOrgName: true,
    clickToOpen: true,
    onClick: (projectId) => {
      history.push(getProjectOverviewRoute(projectId));
    },
  };

  const config = {
    onLoadMore: () => {
      if (isFetchedAll) return;

      fetchData({ lastKey: startAfter });
    },
  };

  const onClickClear = () => {
    setBelongingToOrg(BELONGING_TO_VALUE.ALL);
    resetData();
    fetchData({ belongingToOrg: BELONGING_TO_VALUE.ALL });
  };

  const onSelectBelonging = (ev) => {
    const { value } = ev.target;
    setBelongingToOrg(value);
    resetData();
    fetchData({ belongingToOrg: value });
  };

  const renderContent = () => {
    if (typeof projectsIds === "undefined") return <Spinner />;

    if (!projectsIds.length && !isRequestSent)
      return (
        <EmptyData
          title={
            searchValue
              ? ProjectStrings.LIST_NO_PROJECTS
              : ProjectStrings.LIST_NO_FOR_SEARCH
          }
          icon={searchValue ? <ViewCarouselOutlinedIcon /> : <SearchIcon />}
        />
      );

    return (
      <InfiniteScroll config={config} size={projectsIds.length}>
        <div className={classes.main}>
          {projectsIds.map((projectId) => (
            <div key={projectId} className={classes.cardContainer}>
              <ProjectCard projectId={projectId} config={configCard} />
            </div>
          ))}
        </div>
      </InfiniteScroll>
    );
  };

  return (
    <div className={classes.root}>
      {loading && <Spinner />}
      <div className={classes.filter}>
        <FormControl className={classes.formControl}>
          <InputLabel id="belonging" className={classes.formLabel}>
            {ProjectStrings.PROJECTS_FILTER_ORGANISATION_NAME}
          </InputLabel>
          <Select
            style={{ padding: "6px" }}
            value={belongingToOrg}
            onChange={onSelectBelonging}
          >
            {BELONGING_TO_OPTIONS.map((opt) => {
              return (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button style={{ marginTop: "16px" }} onClick={onClickClear}>
          {DefaultStrings.BUTTON_CLEAR}
        </Button>
      </div>
      {renderContent()}
    </div>
  );
};

export default Projects;
